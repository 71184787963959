import React from "react";
import styled from "styled-components";

const GlobalContainer = styled.div`
  padding: 2.5rem 2rem;
  font-size: 0.75rem;
  margin: 0;
  color: #363636;
  height: 100%;
  cursor: default;

  @media only screen and (min-width: 1000px) {
    padding: 5.25rem 5rem;
    font-size: 1.5rem;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
`;

const Layout = ({ location, title, children }: { location: any, title: string, children: any; }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  return (
    <GlobalContainer data-is-root-path={isRootPath}>
      <main>{children}</main>
      {/* <Footer>
        <a href="https://www.youtube.com/watch?v=knCj92zA0tU">For All Occasions</a>
        {`, `}
        {new Date().getFullYear()}
      </Footer> */}
    </GlobalContainer>
  );
};

export default Layout;
